import { useNavigate } from "react-router-dom";
import "./CategoryProduct.css"
export const CategoryProduct = ({item}) => {
 
  const navigate= useNavigate();
  const handleClick = () => {
    window.open(item.grab_deal_link, "_blank");
  };

  const imageClick = () => {
  
    navigate(`/post/${item.unique_id}/${item.slug}`)
  };

  return (
    <>
      <section className="card_box_category" >
        <div className="product_img" >
         <img src={item.image_url} alt={item.title} className="card-image-category"  onClick={imageClick}/>
        </div>

        <div className="card-details">
            <div className="card-title"><p onClick={imageClick}>{item.store_title}</p></div>
          
          <section className="card-description">
          
            <span className="total-description">{item.title}</span>
          </section>
          <section className="card-price_category" onClick={handleClick}>
            <div className="price">
             
                <span>GRAB DEAL</span> 
            </div>

            <div className="off_percent">{item.discount}% Off</div>
          </section>
          
        </div>
      </section>
    </>
  );
};