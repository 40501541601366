import "./App.css";
import { Home } from "./Pages/Home";
import { Route, Routes, useLocation } from "react-router-dom";
import { Store } from "./Pages/Stores";
import { Categories } from "./Pages/Categories";
import { SubmitDeals } from "./Pages/SubmitDeals";
import { Educators } from "./Pages/Educators";
import { ContactUs } from "./Pages/ContactUs";
import { NavbarMain } from "./Components/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Login } from "./Components/Login";
import { Register } from "./Components/Register";
import { SinglePage } from "./Components/SinglePage";
import { SingleCategoryPage } from "./Components/SingleCategoryPage";
import { PrivateRoute } from "./Components/PrivateRoute";
import { ForgotPasswordModal } from "./Components/ForgotPasword";
import { Forum } from "./Components/Forum";
import { EducatorDetail } from "./Components/EducatorDetail";
import { SinglePageProduct } from "./Components/SinglePageProduct";
import { SearchPage } from "./Components/SearchPage";
import { Profile } from "./Components/Profile";
import { EditProfilePage } from "./Components/EditProfilePage";
import { NewDeals } from "./Components/NewDeals";
import { CommonProfile } from "./Components/CommonProfile";
import { Payment } from "./Components/Payment";
import { VerifyAccount } from "./Components/VerifyAccount";
import { ConfirmEmail } from "./Components/ConfirmEmail";
import { PrivacyPolicy } from "./Components/PrivacyPolicy";
import "../src/Pages/Home.css";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { ScrollToTopOnPageChange } from "./Components/ScrollToTopOnPageChange";
import { MetaTitleAndDescription } from "./Components/MetaTitleAndDescription";
import DeleteAccount from "./Pages/DeleteAccount";

function App() {
  const [loginStatus, setLoginStatus] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const storedLoginStatus = Cookies.get("loginStatusBwcDeals");
    if (storedLoginStatus) {
      setLoginStatus(JSON.parse(storedLoginStatus));
    }
  }, []);

  useEffect(() => {
    Cookies.set("loginStatusBwcDeals", JSON.stringify(loginStatus));
  }, [loginStatus]);

  const handleLogin = () => {
    setLoginStatus(true);
  };

  const handleLogout = () => {
    
    setLoginStatus(false);
  };
  // const isNotFoundPage = location.pathname === "/logout";
  const isNotFoundPage = location.pathname === "/logout"
     
    // || location.pathname === "/post/:unique_id/:slug" || location.pathname === "/store"
    // || location.pathname === "/store/:slug" || location.pathname === "/category" || location.pathname === "/category/:slug" || location.pathname === "/submitdeal"
    // || location.pathname === "/user/profile" || location.pathname === "/user/profile-edit" || location.pathname === "/profile/:user_id" || location.pathname === "/privacy-policy" || location.pathname === "/educator"
    // || location.pathname === "/forum" || location.pathname === "/educator/:uni_id/:slug" || location.pathname === "/contact" || location.pathname === "/login" || location.pathname === "/register"
    // || location.pathname === "/forgotpassword" || location.pathname === "/navbar" || location.pathname === "/newdeals" || location.pathname === "/payment" || location.pathname === "/verify" 
    // || location.pathname === "/confirm/*" || location.pathname === "/search" 
    // ;

    // useEffect(() => {
    //   // Initialize the Facebook SDK
    //   window.fbAsyncInit = function () {
    //     window.FB.init({
    //       appId: process.env.REACT_APP_FACEBOOK_APP_ID,
    //       cookie: true,
    //       xfbml: true,
    //       version: 'v20.0', // Ensure this is the correct version
    //     });
    //   };
  
    //   // Load the Facebook SDK script
    //   (function (d, s, id) {
    //     var js, fjs = d.getElementsByTagName(s)[0];
    //     if (d.getElementById(id)) { return; }
    //     js = d.createElement(s); js.id = id;
    //     js.src = "https://connect.facebook.net/en_US/sdk.js";
    //     fjs.parentNode.insertBefore(js, fjs);
    //   }(document, 'script', 'facebook-jssdk'));
    // }, []);
  
  return (

    <>
   <div className="App">
      {!isNotFoundPage && (
        <NavbarMain loginStatus={loginStatus} onLogout={handleLogout} />
      )}
      {/* <NavbarMain loginStatus={loginStatus} onLogout={handleLogout} /> */}
      <ScrollToTopOnPageChange />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/post/:unique_id/:slug" element={<SinglePageProduct />} />
        <Route path="/store" element={<Store />} />
        <Route path="/store/:slug" element={<SinglePage />} />
        <Route path="/category" element={<Categories />} />
        <Route path="/category/:slug" element={<SingleCategoryPage />} />
        <Route
          path="/submitdeal"
          element={
            <PrivateRoute>
              <SubmitDeals />
            </PrivateRoute>
          }
        />
        <Route path="/user/profile" element={<Profile />} />
        <Route path="/user/profile-edit" element={<EditProfilePage />} />
        <Route path="/profile/:user_id" element={<CommonProfile />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/educator" element={<Educators />} />
        <Route path="/forum" element={<Forum />} />
        <Route path="/educator/:uni_id/:slug" element={<EducatorDetail />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/register" element={<Register onLogin={handleLogin} />} />
        <Route path="/forgotpassword" element={<ForgotPasswordModal />} />
        <Route path="/navbar" element={<NavbarMain />} />
        <Route path="/newdeals" element={<NewDeals />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/verify" element={<VerifyAccount />} />
        <Route path="/confirm/*" element={<ConfirmEmail />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/delete-account" element={<DeleteAccount onLogout={handleLogout}/>} />
        {/* <Route
          path="*"
          element={
            <div class="error-404-block text-center mt-5">
              <h3 class="error-heading">Oops!</h3>
              <h1 class="error-name">404</h1>
              <h3 class="error-subheading">Page Not Found</h3>
              <div class="home-btn">
                <a
                  href={process.env.REACT_APP_FRONTEND_API}
                  title="Home"
                  class="btn btn-primary"
                >
                  Go To Home
                </a>
              </div>
            </div>
          }
        /> */}
      </Routes>
    </div>

    </>
  );
}

export default App;







