import React from "react";
import { Alert, Form } from "react-bootstrap";
import { bwcDealsApi } from "../Components/Api";
import axios from "axios";
import { useState } from "react";
import Cookies from "js-cookie";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../Components/firebase-config";
import { FacebookAuthProvider } from "firebase/auth";

function MyVerticallyCenteredModal(props) {
  const [id, setId] = useState(props.userId);
  const [del, setDel] = useState(false);
  // console.log("Props",props.userId)

  const deleteData = async (id) => {
    // console.log("Delete user",id)
    const csrftoken = Cookies.get("csrftoken");
    try {
      const response = await axios.post(
        `${bwcDealsApi}/user_deleted`,
        { user_id: id },
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
        }
      );
      //    console.log("Delete",response)

      props.handleMessage(true);
      Cookies.remove("loginTokenBwcDeals");
    } catch (err) {
      // console.log("Delete error", err);
      // setError(err.response.data.message);
      // setSuccess(null);
      // setTimeout(() => {
      //   setError(null);
      // }, 3000);
    }
  };
  useEffect(() => {
    if (del) {
      deleteData(id);
    }
  }, [del]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      // centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Account
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <h4>Centered Modal</h4> */}
        <p>Are you want to delete account ?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setDel(true)}>Yes</Button>
        <Button onClick={props.onHide}>No</Button>
      </Modal.Footer>
    </Modal>
  );
}

const DeleteAccount = ({ onLogout }) => {
  document.title = "- BwcDeals";
  const [isnameFocused, setIsNameFocused] = useState(false);
  const [ispswdFocused, setIsPswdFocused] = useState(false);
  const initialValues = { email: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setFormErrors(validate(formValues));
    setIsSubmit(true);
    const csrftoken = Cookies.get("csrftoken");
    // console.log(formValues)
    // setModalShow(true)
    if (formValues.email !== "" && formValues.password !== "") {
      try {
        const response = await axios.post(`${bwcDealsApi}/signin`, formValues, {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
        });
        //    console.log(response.data.response.user_id)

        setUserId(response.data.response.user_id);
        setModalShow(true);
      } catch (err) {
        // console.log(err);
        setError(err.response.data.message);
        setSuccess(null);
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
    }
  };

  const validate = (values) => {
    const errors = {};

    if (values.email === "") {
      errors.email = "Email is required !";
    }
    if (values.password === "") {
      errors.password = "Password is required !";
    }

    return errors;
  };

  const handleNameFocus = () => {
    setIsNameFocused(true);
  };

  const handleNameBlur = () => {
    setIsNameFocused(false);
  };

  const handlePswdFocus = () => {
    setIsPswdFocused(true);
  };

  const handlePswdBlur = () => {
    setIsPswdFocused(false);
  };

  const message = (status) => {
    if (status == true) {
      setModalShow(false);
      setSuccess("User deleted successfully.");
      setFormValues(initialValues);
      onLogout();
      setTimeout(() => {
        setSuccess(null);
      }, 3000);
    }
  };

  const googleProvider = new GoogleAuthProvider();

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const csrftoken = Cookies.get("csrftoken");
      // console.log(result);
      const formData = {
        provider: "google",
        provider_id: result.user.providerData[0].uid,
        name: result.user.providerData[0].displayName,
        email: result.user.providerData[0].email,
        profile_img: result.user.providerData[0].photoURL,
      };
      // console.log("Form data",formData)
      const response = await axios.post(
        `${bwcDealsApi}/social_signin_deleted`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
        }
      );

      // console.log("Response form Data google", response);

      if (response.data.status == "false") {
        setError(response.data.message);
        setSuccess(null);
        setTimeout(() => {
          setError(null);
        }, 3000);
      }else{
        setUserId(response.data.data.response.user_id);
        setModalShow(true);
      }
      
    } catch (err) {
      // console.log("Google Error",err);
      setError(err.response.data.message);
      setSuccess(null);
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  };

  const facebookProvider = new FacebookAuthProvider();

  const signInWithFacebook = async () => {
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      // console.log(result.user.providerData[0]);
      const csrftoken = Cookies.get("csrftoken");

      const formData = {
        provider: "facebook",
        provider_id: result.user.providerData[0].uid,
        name: result.user.providerData[0].displayName,
        email: result.user.providerData[0].email,
        profile_img: result.user.providerData[0].photoURL,
      };
      // console.log("Form data",formData)
      const response = await axios.post(
        `${bwcDealsApi}/social_signin_deleted`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
        }
      );

      // console.log("Response form Facebook Data", response);
      if (response.data.status == "false") {
        setError(response.data.message);
        setSuccess(null);
        setTimeout(() => {
          setError(null);
        }, 3000);
      }else{
        setUserId(response.data.data.response.user_id);
        setModalShow(true);
      }
      
    } catch (err) {
      // console.log("Facebook error",err);
      setError(err.response.data.message);
      setSuccess(null);
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-md-center">
          <div className="col-xxl-4 col-xl-6 col-md-8 col-lg-7 col-sm-11 loginAndForm">
            <div class="container mt-4">
              <div class="login-container">
                {success && (
                  <Alert variant="success" style={{ fontSize: "20px" }}>
                    {success}
                  </Alert>
                )}
                {error && (
                  <Alert variant="danger" style={{ fontSize: "20px" }}>
                    {error}
                  </Alert>
                )}

                <form onSubmit={handleSubmit}>
                  <Form>
                    <p className="login_text">Delete Account</p>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <div className="mb-3">
                        <Form.Control
                          type="email"
                          className="input_box"
                          placeholder="Email"
                          onFocus={handleNameFocus}
                          onBlur={handleNameBlur}
                          style={{
                            border: isnameFocused
                              ? "1px solid rgba(53, 63, 102, 1)"
                              : "1px solid #ced4da",
                            boxShadow: isnameFocused ? "none" : "none",
                          }}
                          name="email"
                          value={formValues.email}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                        <p className="blank_message">{formErrors.email}</p>
                      </div>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput2">
                      <div className="mb-3">
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          className="input_box"
                          onFocus={handlePswdFocus}
                          onBlur={handlePswdBlur}
                          style={{
                            border: ispswdFocused
                              ? "1px solid rgba(53, 63, 102, 1)"
                              : "1px solid #ced4da",
                            boxShadow: ispswdFocused ? "none" : "none",
                          }}
                          name="password"
                          value={formValues.password}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                        <p className="blank_message">{formErrors.password}</p>
                      </div>
                    </Form.Group>
                  </Form>
                  {/* <p className="forgot-password">
                    <span onClick={handleForgotPassword}>Forgot password?</span>
                  </p> */}
                  <button type="submit" className="login_button">
                    Submit
                  </button>
                </form>

                <p className="or_para">Or</p>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        className="form-group btn btn-primary fb-btn"
                        onClick={signInWithFacebook}
                      >
                        <FontAwesomeIcon
                          icon={faFacebookSquare}
                          className="svg_icon"
                        />
                        LOGIN WITH FACEBOOK
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div
                        className="form-group btn btn-primary gplus-btn"
                        onClick={signInWithGoogle}
                      >
                        <FontAwesomeIcon icon={faGoogle} className="svg_icon" />
                        LOGIN WITH GOOGLE
                      </div>
                    </div>
                  </div>
                </div>
                {modalShow && (
                  <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    userId={userId}
                    handleMessage={message}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteAccount;
