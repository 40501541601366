import React from "react";
import { Footer } from "./Footer";
import { Badge, Breadcrumb } from "react-bootstrap";
import facebook from "../Pictures/fb.png";
import twitter from "../Pictures/twitter.png";
import pintrest from "../Pictures/pintrest.png";
import linkedin from "../Pictures/linkedin.png";
import like from "../Pictures/like.png";
import already_like from "../Pictures/already_like.png";
import unlike from "../Pictures/unlike.png";
import already_unlike from "../Pictures/already_dislike.png";
import "./SinglePageProduct.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { bwcDealsApi } from "./Api";
import Cookies from "js-cookie";
import { Comment } from "./Comment";
import { Helmet } from "react-helmet";
import { FacebookIcon, FacebookShareButton } from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { MetaTitleAndDescription } from "./MetaTitleAndDescription";
import DOMPurify from "dompurify";

export const SinglePageProduct = () => {
  // document.title="- BwcDeals"
  const [data, setData] = useState("");
  const { unique_id, slug } = useParams();
  const [isLiked, setIsLiked] = useState(false);

  const token = JSON.parse(
    Cookies.get("loginTokenBwcDeals") === undefined
      ? null
      : Cookies.get("loginTokenBwcDeals")
  );
  // console.log("Token",token)
  const [comments, setComments] = useState("");
  const [newComment, setNewComment] = useState([]);
  const [profile, setProfile] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [userImage, setUserImage] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const csrftoken = Cookies.get("csrftoken");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    if (token) {
      axios
        .post(
          `${bwcDealsApi}/user_dashboard`,
          {
            user_id: token && token.response.user_id,
            // loggedin_user_id: token.response.user_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrftoken,
            },
          }
        )

        .then((res) => {
          setProfile(res.data.response.user_data);
        })
        .catch();
    }
  };

  useEffect(() => {
    setIsLiked(true);
    setLoading(true);
    if (token) {
      axios
        .post(
          `${bwcDealsApi}/product_details`,
          {
            user_id: token.response.user_id,
            unique_id: unique_id,
            slug: slug,
            type: "d",
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrftoken,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          setData(res.data.response.product_details);
          setUserImage(res.data.response.comments_user_image);
          setNewComment(res.data.response.comments);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      axios
        .post(
          `${bwcDealsApi}/product_details`,
          {
            unique_id: unique_id,
            slug: slug,
            type: "d",
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrftoken,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          setData(res.data.response.product_details);
          setUserImage(res.data.response.comments_user_image);
          setIsLiked(true);
        })
        .catch((err) => {
          setLoading(false);
        });
    }

    // fetchData();
  }, []);

  const handleGrabDeal = () => {
    window.open(`${data.grab_deal_link}`, "_blank");
  };

  const handlePostNewTopic = () => {
    if (token === "null") {
      navigate("/login");
    } else {
      navigate("/submitdeal");
    }
  };

  const handleLike = () => {
    if (token) {
      if (+data.is_liked) {
        axios
          .post(
            `${bwcDealsApi}/product_like_unlike`,
            {
              user_id: token && token.response.user_id,
              post_id: data.post_id,
              post_type: data.post_type,
              btn_type: "like",
            },
            {
              headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrftoken,
              },
            }
          )
          .then((res) => {
            setIsLiked(true);
            window.location.reload();
          })
          .catch();
      }
    } else {
      navigate("/login");
    }
  };
  const handleUnLike = () => {
    if (token) {
      if (data.is_liked) {
        axios
          .post(
            `${bwcDealsApi}/product_like_unlike`,
            {
              user_id: token && token.response.user_id,
              post_id: data.post_id,
              post_type: data.post_type,
              btn_type: "unlike",
            },
            {
              headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrftoken,
              },
            }
          )
          .then((res) => {
            setIsLiked(true);
            window.location.reload();
          })
          .catch((error) => {});
      }
    } else {
      navigate("/login");
    }
  };

  let url = `${process.env.REACT_APP_FRONTEND_API}/post/${unique_id}/${slug}`;
// console.log(url)
  const handleNewComment = () => {
    if (token !== null) {
      if (comments.trim() !== "") {
        let collectedData = {
          user_id: token && token.response.user_id,
          post_id: data.post_id,
          post_type: data.post_type,
          comment_id: "",
          comment_text: comments,
        };

        axios
          .post(`${bwcDealsApi}/product_add_comment`, collectedData, {
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrftoken,
            },
          })
          .then((res) => {
            setNewComment("");
            setErrorMessage("");
            window.location.reload();
          })
          .catch((error) => {});
      } else {
        setErrorMessage("Please enter a comment before posting.");
      }
    } else {
      navigate("/login");
    }
  };

  const pos = data.post_id;
  const type = data.post_type;

  // console.log("Data", data);

  const metadata = {
    title: data.title || "- BwcDeals",
    description: DOMPurify.sanitize(data.description, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] })|| "Product Description",
    image: data.image_url || "https://example.com/default-image.jpg",
    url: `${process.env.REACT_APP_FRONTEND_API}/post/${unique_id}/${slug}`,
  };

  const handlePintrest = () => {
    const description = encodeURIComponent(data && data.title);
    const imageUrl = encodeURIComponent(data && data.image_url);
    const url = encodeURIComponent(
      `${process.env.REACT_APP_FRONTEND_API}/post/${unique_id}/${slug}`
    );

    const pinterestShareUrl = `https://in.pinterest.com/pin-builder/?description=${description}&media=${imageUrl}&url=${url}`;

    window.open(pinterestShareUrl, "_blank", "width=600,height=400");
  };
  // document.title= data.title

  // console.log("description", metadata);

  // useEffect(() => {
  //   // Update the title
  //   // document.title = 'Your Website Title';

  //   // Create a meta element for og:title
  //   const ogTitleMeta = document.createElement('meta');
  //   ogTitleMeta.setAttribute('property', 'og:title');
  //   ogTitleMeta.setAttribute('content', `${data && data.title}`);
  //   document.head.appendChild(ogTitleMeta);

  //   // Create a meta element for og:description
  //   const ogDescriptionMeta = document.createElement('meta');
  //   ogDescriptionMeta.setAttribute('property', 'og:description');
  //   ogDescriptionMeta.setAttribute(
  //     'content',
  //     `${data.description || "Product Details"}`
  //     );
  //   document.head.appendChild(ogDescriptionMeta);

  //   // Create a meta element for og:image
  //   const ogImageMeta = document.createElement('meta');
  //   ogImageMeta.setAttribute('property', 'og:image');
  //   ogImageMeta.setAttribute(
  //     'content',
  //     `${data.image_url}`
  //   );
  //   document.head.appendChild(ogImageMeta);

  //   // Create a meta element for og:url
  //   const ogUrlMeta = document.createElement('meta');
  //   ogUrlMeta.setAttribute('property', 'og:url');
  //   ogUrlMeta.setAttribute(
  //     'content',
  //     `${process.env.REACT_APP_FRONTEND_API}/post/${unique_id}/${slug}`
  //   );
  //   document.head.appendChild(ogUrlMeta);

  //   // Clean up function to remove the added meta tags
  //   return () => {
  //     document.head.removeChild(ogTitleMeta);
  //     document.head.removeChild(ogDescriptionMeta);
  //     document.head.removeChild(ogImageMeta);
  //     document.head.removeChild(ogUrlMeta);
  //   };
  // }, [data]);

  // useEffect(()=>{
  //   if( data &&  data.title){
  //     // console.log(data.title)
  //     document.title= data.title
  //   }
  // },[data])

  document.title = `${data.title == undefined ? "- BwcDeals" : data.title}`;
  // console.log("Data",data)

  // const shareUrl = `${process.env.REACT_APP_FRONTEND_API}/post/${unique_id}/${slug}`;
  // const encodedTitle = encodeURIComponent(data.title);
  // const encodedDescription = encodeURIComponent(data.description);
  // const encodedImageUrl = encodeURIComponent(data.image_url);
// let d= {
//   method: 'share',
//   href: `${process.env.REACT_APP_FRONTEND_API}/post/${unique_id}/${slug}`,
//   quote: `${data.title} - ${data.description}`,
//   hashtag: '#BWCDeals',
//   display: 'popup',
//   picture: data.image_url,
// }
// console.log(d)



  const shareProduct = () => {
    if (window.FB) {
      window.FB.ui({
        method: 'share',
        href: `${process.env.REACT_APP_FRONTEND_API}/post/${unique_id}/${slug}`,
        quote: `${data.title} - ${data.description}`,
        hashtag: '#BWCDeals',
        display: 'popup',
        picture: data.image_url,
      }, function (response) {
        if (response && !response.error_message) {
          // alert('Sharing successful');
          // console.log("Response",response)
        } else {
          // console.log("ERROR",response)
          // alert('Error while sharing');
        }
      });
    } else {
      alert('Facebook SDK not loaded yet.');
    }
  };
  return (
    <>
    <div>
      {data && <Helmet>
        {/* <meta property="og:title" content={data && data.title} />
        <meta
          property="og:description"
          content={data && data.description}
        />
        <meta
          property="og:image"
          content={data && data.image_url}
        />
        <meta
          property="og:url"
          content={`https://www.api.thedealer.ie/product/${unique_id}/${slug}`}
        /> */}

        <meta property="og:url" content={`${process.env.REACT_APP_FRONTEND_API}/post/${unique_id}/${slug}`} />
        <meta property="og:title" content={data && data.title} />
        <meta property="og:description" content={(data && data.description) || "Like Deals and supporting Teachers? Then you are at the right place!!"}/>
        <meta property="og:type" content="website" />
        <meta property="og:image" content={data && data.image_url}/>
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:site_name" content="BWC Deals" />
        <meta property="og:locale" content="en_US" />

        <meta property="twitter:url" content={`${process.env.REACT_APP_FRONTEND_API}/post/${unique_id}/${slug}`}/>
        <meta property="twitter:title" content={data && data.title}/>
        <meta property="twitter:description" content={(data && data.description) || "Like Deals and supporting Teachers? Then you are at the right place!!"}/>
        <meta property="twitter:image" content={data && data.image_url}/>
        <meta property="twitter:domain" content="BWC Deals" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:creator" content="@twitter" />
        <meta property="twitter:site" content="@twitter" />
      </Helmet>}

      {loading ? (
        <div className="loading-spinner">
          {/* <FontAwesomeIcon icon={faSpinner} spin fontSize="60px" /> */}
        </div>
      ) : (
        <div className="container">
          <h2 style={{ marginTop: "20px" }}>{data.title}</h2>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/forum">Forums</Breadcrumb.Item>
            <Breadcrumb.Item href="/newdeals">New Deals</Breadcrumb.Item>
            <Breadcrumb.Item active>{data.title}</Breadcrumb.Item>
          </Breadcrumb>

          <div className="clear_list clear_list_single_product_page">
            <div className="list_img">
              <img
                src={data.image_url}
                alt={data.title}
                className="listing_img"
              />
              <Badge bg="danger" className="offer_badge">
                {data.deal_badge}
              </Badge>
            </div>

            <div className="list_text">
              <p className="product_name">{data.title}</p>
              <div>
                <div>
                  <span className="product_price">
                    {data.currency} {data.price}
                  </span>
                </div>
                <button className="deal_btn" onClick={handleGrabDeal}>
                  GRAB DEAL
                </button>
              </div>
              <div className="social_media_div_grab_deal">
                <span onClick= {shareProduct}
                  // href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  //   url
                  // )}&text=Sharing`}

                  // href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&quote=${encodedTitle}%20-%20${encodedDescription}&picture=${encodedImageUrl}`}
                  // target="_blank"
                >
                  <img
                    src={facebook}
                    alt="facebook"
                    className="social_list_product"
                  />
                </span>

                {/* <FacebookShareButton
          url="https://www.pakkamarwadi.tk/"
          quote={'Title or jo bhi aapko likhna ho'}
          title="Bscdk"
          // hashtag={'#portfolio...'}
        >
          <FacebookIcon size={40} round={true} />
        </FacebookShareButton> */}
                <a
                  href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                    url
                  )}&text=Sharing`}
                  target="_blank"
                >
                  <img
                    src={twitter}
                    alt="twitter"
                    className="social_list_product"
                  />
                </a>
                <span>
                  <img
                    src={pintrest}
                    alt="pintrest"
                    className="social_list_product"
                    onClick={handlePintrest}
                  />
                </span>
                <a
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                    url
                  )}`}
                  target="_blank"
                >
                  <img
                    src={linkedin}
                    alt="linkedin"
                    className="social_list_product"
                  />
                </a>
              </div>
              <div className="list_like">
                {isLiked && (
                  <img
                    src={
                      token
                        ? data.is_liked == +2
                          ? like
                          : data.is_liked == 1
                          ? already_like
                          : like
                        : like
                    }
                    alt="like"
                    onClick={handleLike}
                    className="like"
                  />
                )}
                <img
                  src={
                    token
                      ? data.is_liked == +2
                        ? unlike
                        : data.is_liked == -1
                        ? already_unlike
                        : unlike
                      : unlike
                  }
                  alt="unlike"
                  onClick={handleUnLike}
                  className="like"
                />
              </div>
            </div>
          </div>

          {data.description && <p className="about_deal">About Deal Discussion</p>}
          <div
            dangerouslySetInnerHTML={{ __html: data.description }}
            className="paragraph_description"
          />
          <div className="comment_sectio">
            {newComment && newComment.length > 0 && (
              <p className="comment_text">Comments</p>
            )}
            {newComment &&
              newComment.map((elem) => {
                return (
                  <div key={elem.id} className="comment_div_section">
                    <Comment
                      elem={{ ...elem, product_id: pos, product_type: type }}
                    />
                  </div>
                );
              })}
          </div>

          {/* Comment section */}
          <div className="comment_box">
            <p className="comment_text">Leave a Comment</p>
            <div className="user_img">
              <div>
                <img
                  src={userImage && userImage[0].user_image}
                  alt="user"
                  className="user_image_profile"
                />
              </div>

              <div className="comment-section">
                <div className="new-comment">
                  <textarea
                    type="text"
                    className="text_area"
                    onChange={(e) => {
                      setComments(e.target.value);
                      setErrorMessage("");
                    }}
                    placeholder="Add a comment..."
                  />
                  {errorMessage && (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  )}
                  <div>
                    <button onClick={handleNewComment} className="comment_btn">
                      Comment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn_post">
            <button className="post_topic_btn" onClick={handlePostNewTopic}>
              POST NEW TOPIC
            </button>
          </div>
        </div>
      )}
      <Footer />
    </div>

    </>
  );
};
