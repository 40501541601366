import { useNavigate } from "react-router-dom";
import "./Product.css"



export const Product = ({item}) => {


    const navigate= useNavigate();
  const handleClick = () => {
  
    navigate(`/post/${item.unique_id}/${item.slug}`)
  };
  const handleMouseClick =(event)=>{
    if (event.button === 1) {
    window.open(`/post/${item.unique_id}/${item.slug}`,"_blank")
    }
  }
  return (
    <>
      <section className="card_box" onClick={handleClick} onMouseDown={handleMouseClick}>
        <div>
        <img src={item.image_url} alt={item.title} className="card-image"  />
        </div>

        <div className="card-details">
            <div className="card-title"><p >{item.merchant_title || item.store_title}</p></div>
          
          <section className="card-description">
            <span className="total-description">{item.title}</span>
          </section>
          <section className="card-price">
            <div className="price">
                Price: <span>$ {item.price}</span> 
            </div>

            <div className="off_percent">{item.discount}% Off</div>
          </section>
          
        </div>
      </section>
    </>
  );
};

