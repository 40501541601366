import React, { useEffect, useState } from "react";
// import { NavbarMain } from "../Components/Navbar";
import { Alert, Breadcrumb } from "react-bootstrap";
import { FeaturedStoreCarousel } from "../Components/FeaturedStoreCarousel";
import "./SubmitDeals.css";
import { FaHandshake } from "react-icons/fa6";
import { Footer } from "../Components/Footer";
import { FaComments } from "react-icons/fa";
import axios from "axios";
import { bwcDealsApi } from "../Components/Api";
import Cookies from "js-cookie";
import { MetaTitleAndDescription } from "../Components/MetaTitleAndDescription";

export const SubmitDeals = () => {
  const [showContent1, setShowContent1] = useState(true);
  const [showContent2, setShowContent2] = useState(false);
  const [activeClass, setActiveClass] = useState(true);
  const [activeClass1, setActiveClass1] = useState(false);

  const [newDeal, setNewDeal] = useState([]);
  const [store, setStore] = useState([]);
  const [category, setCategory] = useState([]);
  document.title = "- BwcDeals";
  const token = JSON.parse(
    Cookies.get("loginTokenBwcDeals") === undefined
      ? null
      : Cookies.get("loginTokenBwcDeals")
  );
  const csrftoken = Cookies.get("csrftoken");
  const [success, setSuccess] = useState(null);
  const [discussionSuccess, setDiscussionSuccess] = useState(null);
  const [discussion, setDiscussion] = useState([]);
  const [defaultDiscussion, setDefaultDiscussion] = useState({
    forum_category_id: "",
  });

  const [defaultValues, setDefaultValues] = useState({
    defaultDealCategoryId: "",
    defaultStoreId: "",
    defaultCategoryId: "",
  });

  useEffect(() => {
    axios
      .get(`${bwcDealsApi}/submit_deal`)
      .then((res) => {
        setNewDeal(res.data.response.forum_category_deal);
        setStore(res.data.response.store);
        setCategory(res.data.response.category);

        setDiscussion(res.data.response.forum_category_discussion);

        setDefaultValues({
          defaultDealCategoryId: res.data.response.forum_category_deal[0].id,
          defaultStoreId: res.data.response.store[0].id,
          defaultCategoryId: res.data.response.category[0].id,
        });
        setDefaultDiscussion({
          forum_category_id: res.data.response.forum_category_discussion[0].id,
        });
      })
      .catch();
  }, []);

  const initialDiscussionValues = {
    user_id: token.response.user_id,
    forum_category_id: "",
    title: "",
    detail: "",
    image: "",
  };
  const [discussionValues, setDiscussionValues] = useState(
    initialDiscussionValues
  );

  const initialValues = {
    user_id: token.response.user_id,
    deal_category: null,
    store_id: null,
    category_id: null,
    title: "",
    price: "",
    discount: "",
    link: "",
    expiry: "",
    type: "d",
    image: "",
    deal_description: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  useEffect(() => {
    // Update formValues when defaultValues change
    setFormValues((prevValues) => ({
      ...prevValues,
      deal_category: defaultValues.defaultDealCategoryId,
      store_id: defaultValues.defaultStoreId,
      category_id: defaultValues.defaultCategoryId,
    }));

    setDiscussionValues((prev) => ({
      ...prev,
      forum_category_id: defaultDiscussion.forum_category_id,
    }));
  }, [defaultValues, defaultDiscussion]);

  const handleFileChange = async (event) => {
    // Get the selected file from the input
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    setFormValues({ ...formValues, [event.target.name]: file });
  };

  const toggleContent = (boxNumber) => {
    if (boxNumber === 1) {
      setShowContent1(true);
      setShowContent2(false);
      setActiveClass(true);
      setActiveClass1(false);
    } else {
      setShowContent1(false);
      setShowContent2(true);
      setActiveClass1(true);
      setActiveClass(false);
    }
  };
  const fieldTypes = {
    deal_category: "number",
    store_id: "number",
    category_id: "number",
    price: "number",
    discount: "number",
    // Add other fields here with their respective types
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const dataType = fieldTypes[name];

    const updatedValue = dataType === "number" ? Number(value) : value;

    setFormValues({ ...formValues, [name]: updatedValue });
  };

  const handleDealSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        `${bwcDealsApi}/save_deal`,
        formValues, // Send the calculated page number in the request
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRFToken": csrftoken,
            // 'Content-Type': 'application/json'
          },
        }
      )

      .then((res) => {
        setSuccess(res.data.message);

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setTimeout(() => {
          setSuccess(null);
          setFormValues(initialValues);
        }, 3000);
      })
      .catch();
  };

  const handleDiscussionChangeImage = (event) => {
    const file = event.target.files[0];
    setDiscussionValues({ ...discussionValues, [event.target.name]: file });
  };
  const handleDiscussionChange = (e) => {
    const { name, value } = e.target;

    setDiscussionValues({ ...discussionValues, [name]: value });
    // setFormErrors(validate(formValues));
  };
  const handleDiscussionSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${bwcDealsApi}/save_discussion`, discussionValues, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRFToken": csrftoken,
        },
      })

      .then((res) => {
        setDiscussionSuccess(res.data.message);
        window.scrollTo({
          top: 0,
          behavior: "smooth", // Optional smooth scrolling
        });
        setTimeout(() => {
          setDiscussionSuccess(null);
          setDiscussionValues(initialDiscussionValues);
          // window.location.reload();
        }, 3000);
      })
      .catch();
  };

  return (
    <>
      <MetaTitleAndDescription />
      <div>
        {/* <NavbarMain /> */}

        <div class="container">
          {/* <!-- breadcrumb --> */}

          {success === "Deal has been Submitted." && (
            <Alert variant="success" className="alert">
              {success}
            </Alert>
          )}
          {discussionSuccess === "Discussion has been Submitted." && (
            <Alert variant="success" className="alert">
              {discussionSuccess}
            </Alert>
          )}

          <div className="educator_div_section">
            <div>
              <h2 style={{ marginTop: "20px" }}>Submit Deals</h2>
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Submit Deals</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="coupon-page-block categories-page submit-deal-page">
            <div className="coupon-dtl-outer">
              <div className="row">
                <div className="col-lg-9 col-md-8">
                  <div className="submit-deal-main-block">
                    <div className="form-group post-type">
                      <label>Select Type</label>

                      <ul className="nav" id="post-type" role="tablist">
                        <li class="nav-item">
                          <div
                            class="post-type-icon"
                            onClick={() => toggleContent(1)}
                            className={
                              activeClass ? "deal_box active" : "deal_box"
                            }
                          >
                            <div className="hand_shake">
                              {" "}
                              <FaHandshake />
                            </div>{" "}
                            Deal
                          </div>
                        </li>

                        <li class="nav-item">
                          <div
                            class="post-type-icon"
                            onClick={() => toggleContent(2)}
                            className={
                              activeClass1 ? "deal_box1 active1" : "deal_box1"
                            }
                          >
                            <div className="hand_shake">
                              {" "}
                              <FaComments />
                            </div>{" "}
                            Discussion
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div class="form-group">
                      <div class="tab-content" id="post-type-content">
                        {
                          <div
                            class="tab-pane fade active show"
                            id="deal"
                            role="tabpanel"
                            aria-labelledby="deal-tab"
                            style={{ display: showContent1 ? "block" : "none" }}
                          >
                            <form
                              class="submit-deal-form"
                              onSubmit={handleDealSubmit}
                              enctype="multipart/form-data"
                              method="post"
                            >
                              <div class="form-group">
                                <label for="forum_category_id">
                                  Choose Deal Category
                                  <span className="star_color">*</span>
                                </label>

                                <select
                                  class="form_control"
                                  required
                                  id="forum_category_id"
                                  name="deal_category"
                                  tabindex="-1"
                                  aria-hidden="true"
                                  value={formValues.deal_category}
                                  onChange={handleChange}
                                >
                                  {/* <option value="3">New Deals</option> */}

                                  {newDeal &&
                                    newDeal.map((item) => (
                                      <option key={item.id} value={+item.id}>
                                        {item.title}
                                      </option>
                                    ))}
                                </select>
                                <span
                                  class="select2"
                                  dir="ltr"
                                  style={{ width: " 765px" }}
                                >
                                  <span class="selection">
                                    <span
                                      class="select2-selection select2-selection--single"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      tabindex="0"
                                      aria-labelledby="select2-forum_category_id-container"
                                    >
                                      <span
                                        class="select2-selection__arrow"
                                        role="presentation"
                                      >
                                        <b role="presentation"></b>
                                      </span>
                                    </span>
                                  </span>
                                  <span
                                    class="dropdown-wrapper"
                                    aria-hidden="true"
                                  ></span>
                                </span>

                                <small class="text-danger"></small>
                              </div>

                              <div class="form-group">
                                <label for="store_id">
                                  Choose Store
                                  <span className="star_color">*</span>
                                </label>

                                <select
                                  class="form_control select2 select2-hidden-accessible"
                                  required
                                  id="store_id"
                                  name="store_id"
                                  tabindex="-1"
                                  aria-hidden="true"
                                  value={formValues.store_id}
                                  onChange={handleChange}
                                >
                                  {store &&
                                    store.map((item) => (
                                      <option key={item.id} value={+item.id}>
                                        {item.title}
                                      </option>
                                    ))}
                                </select>
                                <span
                                  class="select2 select2-container select2-container--default select2-container--below"
                                  dir="ltr"
                                  style={{ width: "765px" }}
                                >
                                  <span class="selection">
                                    <span
                                      class="select2-selection select2-selection--single"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      tabindex="0"
                                      aria-labelledby="select2-category_id-container"
                                    >
                                      <span
                                        class="select2-selection__arrow"
                                        role="presentation"
                                      >
                                        <b role="presentation"></b>
                                      </span>
                                    </span>
                                  </span>
                                  <span
                                    class="dropdown-wrapper"
                                    aria-hidden="true"
                                  ></span>
                                </span>

                                <small class="text-danger"></small>
                              </div>

                              <div class="form-group">
                                <label for="store_id">
                                  Choose Category
                                  <span className="star_color">*</span>
                                </label>

                                <select
                                  class="form_control select2 select2-hidden-accessible"
                                  required
                                  id="store_id"
                                  name="category_id"
                                  tabindex="-1"
                                  aria-hidden="true"
                                  value={+formValues.category_id}
                                  onChange={handleChange}
                                >
                                  {category &&
                                    category.map((item) => (
                                      <option key={item.id} value={+item.id}>
                                        {item.title}
                                      </option>
                                    ))}
                                </select>
                                <span
                                  class="select2 select2-container select2-container--default select2-container--below"
                                  dir="ltr"
                                  style={{ width: "765px" }}
                                >
                                  <span class="selection">
                                    <span
                                      class="select2-selection select2-selection--single"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      tabindex="0"
                                      aria-labelledby="select2-category_id-container"
                                    >
                                      <span
                                        class="select2-selection__arrow"
                                        role="presentation"
                                      >
                                        <b role="presentation"></b>
                                      </span>
                                    </span>
                                  </span>
                                  <span
                                    class="dropdown-wrapper"
                                    aria-hidden="true"
                                  ></span>
                                </span>

                                <small class="text-danger"></small>
                              </div>
                              <div class="form-group">
                                <label for="title">
                                  Enter Deal Name/Title
                                  <span className="star_color">*</span>
                                </label>

                                <input
                                  class="form_control"
                                  placeholder="Enter Detailed Deal Title"
                                  required
                                  name="title"
                                  type="text"
                                  id="title"
                                  value={formValues.title}
                                  onChange={handleChange}
                                />

                                <small class="text-danger"></small>
                              </div>

                              <div class="form-group">
                                <label for="price">Deal Price</label>

                                <input
                                  class="form_control"
                                  placeholder="Enter Actual Price of Deal"
                                  name="price"
                                  type="number"
                                  id="price"
                                  value={formValues.price}
                                  onChange={handleChange}
                                />

                                <small class="text-danger"></small>
                              </div>

                              <div class="form-group">
                                <label for="discount">Deal Discount</label>

                                <input
                                  class="form_control"
                                  placeholder="Enter Deal Discount"
                                  name="discount"
                                  type="number"
                                  id="discount"
                                  value={formValues.discount}
                                  onChange={handleChange}
                                />

                                <small class="text-danger"></small>
                              </div>

                              <div class="form-group">
                                <label for="link">Deal Product URL/Link</label>

                                <input
                                  className="form_control"
                                  placeholder="https://www.foo.com"
                                  name="link"
                                  type="text"
                                  id="link"
                                  value={formValues.link}
                                  onChange={handleChange}
                                />

                                <small class="text-danger"></small>
                              </div>

                              <div class="form-group">
                                <label for="expiry">Deal Expiry Date</label>

                                <input
                                  class="form_control date-picker"
                                  name="expiry"
                                  type="date"
                                  id="expiry"
                                  value={formValues.expiry}
                                  onChange={handleChange}
                                />

                                <small class="text-danger"></small>
                              </div>

                              <div class="form-group">
                                <label for="detail">
                                  Deal Description
                                  <span className="star_color">*</span>
                                </label>

                                <textarea
                                  class="form_control"
                                  placeholder="Enter Detailed Description"
                                  required
                                  name="deal_description"
                                  cols="50"
                                  rows="10"
                                  id="detail"
                                  style={{ height: "187px" }}
                                  value={formValues.deal_description}
                                  onChange={handleChange}
                                ></textarea>

                                <small class="text-danger"></small>
                              </div>
                              <div class="form-group">
                                <label for="deal_image">Coupon Image</label>{" "}
                                <p class="inline info">
                                  Please enter deal image link
                                </p>
                                <input
                                  className="form_control"
                                  name="deal_image"
                                  type="text"
                                  id="deal_image"
                                  value={formValues.deal_image}
                                  onChange={handleChange}
                                />
                                <small class="text-danger"></small>
                              </div>

                              <div className="form-group input-file-block">
                                <label for="image">Choose Deal Image</label> -{" "}
                                <p className="inline info"></p>
                                <input
                                  className="input-file"
                                  id="image"
                                  name="image"
                                  type="file"
                                  accept="image/*"
                                  // value={formValues.image}
                                  onChange={handleFileChange}
                                  // onChange={handleChange}
                                />
                              </div>

                              <div class="form-group">
                                <div class="submit-deal-btn">
                                  <button type="submit" class="btn btn-primary">
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </form>
                            <div className="featured_carousel_small_screen">
                              <FeaturedStoreCarousel />
                            </div>
                          </div>
                        }

                        {
                          <div
                            class="tab-pane fade active show"
                            id="deal"
                            role="tabpanel"
                            aria-labelledby="deal-tab"
                            style={{ display: showContent2 ? "block" : "none" }}
                          >
                            <form
                              class="submit-deal-form"
                              enctype="multipart/form-data"
                              onSubmit={handleDiscussionSubmit}
                            >
                              <div class="form-group">
                                <label for="forum_category_id">
                                  Choose Discussion Category
                                  <span className="star_color">*</span>
                                </label>

                                <select
                                  class="form_control select2 select2-hidden-accessible"
                                  required
                                  id="forum_category_id"
                                  name="forum_category_id"
                                  tabindex="-1"
                                  aria-hidden="true"
                                  value={discussionValues.forum_category_id}
                                  onChange={handleDiscussionChange}
                                >
                                  {discussion &&
                                    discussion.map((item) => (
                                      <option key={item.id} value={item.id}>
                                        {item.title}
                                      </option>
                                    ))}
                                </select>
                                <span
                                  class="select2 select2-container select2-container--default select2-container--below"
                                  dir="ltr"
                                  style={{ width: " 765px" }}
                                >
                                  <span
                                    class="dropdown-wrapper"
                                    aria-hidden="true"
                                  ></span>
                                </span>

                                <small class="text-danger"></small>
                              </div>

                              <div class="form-group">
                                <label for="store_id">
                                  Discussion Title
                                  <span className="star_color">*</span>
                                </label>
                                <input
                                  class="form_control"
                                  placeholder="Enter Discussion Title"
                                  required
                                  name="title"
                                  type="text"
                                  id="title"
                                  value={discussionValues.title}
                                  onChange={handleDiscussionChange}
                                />

                                <small class="text-danger"></small>
                              </div>

                              <div class="form-group">
                                <label for="detail">
                                  Discussion Description
                                  <span className="star_color">*</span>
                                </label>

                                <textarea
                                  class="form_control"
                                  placeholder="Enter Detailed Description"
                                  required
                                  name="detail"
                                  cols="50"
                                  rows="10"
                                  id="detail"
                                  style={{ height: "187px" }}
                                  value={discussionValues.detail}
                                  onChange={handleDiscussionChange}
                                ></textarea>

                                <small class="text-danger"></small>
                              </div>

                              <div class="form-group input-file-block">
                                <label for="image">Discussion Image</label> -{" "}
                                <p class="inline info"></p>
                                <input
                                  class="input-file"
                                  id="image"
                                  name="image"
                                  type="file"
                                  accept="image/*"
                                  // value={discussionValues.image}
                                  onChange={handleDiscussionChangeImage}
                                />
                                {/* <p class="info">Choose custom image</p> */}
                                <small class="text-danger"></small>
                              </div>

                              <div class="form-group">
                                <div class="submit-deal-btn">
                                  <button type="submit" class="btn btn-primary">
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </form>
                            <div className="featured_carousel_small_screen">
                              <FeaturedStoreCarousel />
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-md-4">
                  <div class="coupon-sidebar">
                    <div class="page-sidebar-widget popular-store-widget">
                      <div className="featured_carousel_long_div">
                        <FeaturedStoreCarousel />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
