import React from "react";
import { FaRegEye, FaRegStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./Deal.css";

export const Deal = ({ item}) => {
  // document.title="- BwcDeals"
  // console.log("Item",item.coupon_id)
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/post/${item.unique_id}/${item.slug}`);
  };



  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();

    const diffInMilliseconds = now - date;
    const diffInSeconds = diffInMilliseconds / 1000;
    const diffInMinutes = diffInSeconds / 60;
    const diffInHours = diffInMinutes / 60;
    const diffInDays = diffInHours / 24;
    const diffInMonths = diffInDays / 30.44; 
    const diffInYears = diffInDays / 365.25;

    if (diffInYears >= 1) {
      return Math.floor(diffInYears) + " years ago";
    } else if (diffInMonths >= 1) {
      return Math.floor(diffInMonths) + " months ago";
    } else if (diffInDays >= 1) {
      return Math.floor(diffInDays) + " days ago";
    } else if (diffInHours >= 1) {
      return Math.floor(diffInHours) + " hours ago";
    } else if (diffInMinutes >= 1) {
      return Math.floor(diffInMinutes) + " minutes ago";
    } else {
      return "Just now";
    }
  };


  const handleProfile = (event) => {
    event.stopPropagation()
    navigate(`/profile/${item.user_id}`)
  }



  return (
    <>
      <section className="deal_card_box" onClick={handleClick} >
        

        <div>
          <img
            src={item.image_url}
            alt={item.store_title}
            className="card-image"
          />
        </div>

        <div className="card-details">
          <div className="card-title">
            <p>{item.store_title}</p>
          </div>

          <section className="deal-description">
            <span className="total-description">{item.title}</span>
          </section>
          <section className="card-price">
            <div className="price">
              Price: <span>$ {item.price}</span>
            </div>

            <div className="off_percent">{item.discount}% Off</div>
          </section>
         
        </div>
        <div className="deal_rating_box">
            <div>
            <span>
              <FaRegStar /> {item.rating}
            </span>
            <p>
              <FaRegEye /> {item.views}
            </p>
            </div>
            <div className="deal_user_detail">
            <div>
              <img src={item.user_image} alt="user" className="deal_user_image" onClick={handleProfile} title="user"/>
            </div>
            <div className="user_detail">
            <span>
              {item.user_name || item.user_by}
            </span>
            <p>
            {formatDate(item.created_at)}
            </p>
            </div>
            </div>
          </div>
      </section>

      

    </>
  );
};